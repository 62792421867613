export const types = {
  //Auth actions//
  LOGIN_START: "LOGIN_START",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  REGISTER_USER_START: "REGISTER_USER_START",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "REGISTER_USER_FAILURE",

  SET_AUTH: "SET_AUTH",
  //Auth Actions//

  //Get Listing Actions
  GET_LISTING_START: "GET_LISTING_START",
  GET_LISTING_SUCCESS: "GET_LISTING_SUCCESS",
  GET_LISTING_FAILURE: "GET_LISTING_FAILURE",

  //Post a Listing Actions
  POST_LIST_START: "POST_LIST_START",
  POST_LIST_SUCCESS: "POST_LIST_SUCCESS",
  POST_LIST_FAILURE: "POST_LIST_FAILURE",

  //Edit a Listing Actions
  UPDATE_LISTING_START: "UPDATE_LISTING_START",
  UPDATE_LISTING_SUCCESS: "UPDATE_LISTING_SUCCESS",
  UPDATE_LISTING_FAILURE: "UPDATE_LISTING_FAILURE",

  // listing action delete
  DELETE_LISTING_START: "DELETE_LISTING_START",
  DELETE_LISTING_SUCCESS: "DELETE_LISTING_SUCCESS",
  DELETE_LISTING_FAILURE: "DELETE_LISTING_FAILURE",

  //Booking a Listing

  BOOKING_LISTING_START: "BOOKING_LISTING_START",
  BOOKING_LISTING_SUCCESS: "BOOKING_LISTING_SUCCESS",
  BOOKING_LISTING_FAILURE: "BOOKING_LISTING_FAILURE",

  //Delete a Booking
  DELETE_BOOKING_START: "DELETE_BOOKING_START",
  DELETE_BOOKING_SUCCESS: "DELETE_BOOKING_SUCCESS",
  DELETE_BOOKING_FAILURE: "DELETE_BOOKING_FAILURE",

  // GET USER
  GET_USER_START: "GET_USER_START",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",

  // EDIT USER
  EDIT_USER_START: "EDIT_USER_START",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "EDIT_USER_FAILURE",

  //DELETE USER ACCOUNT

  DELETE_USER_START: "DELETE_USER_START",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE"
};
