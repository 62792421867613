import React from "react";
//import LogoutHeader from "../components/logoutHeader";
//import LoginHeader from "../components/loginHeader";

// const signOut = e => {
//   localStorage.removeItem("token");
// };

const Header = props => {
  return <div></div>;
};

export default Header;
